import axios from 'src/utils/axios';

export const GET_CONTACTS = '@chat/get-contacts';
export const GET_THREADS = '@chat/get-threads';
export const GET_THREAD = '@chat/get-thread';
export const MARK_THREAD_AS_SEEN = '@chat/mark-thread-as-seen';
export const ADD_MESSAGE = '@chat/add-message';
export const OPEN_SIDEBAR = '@chat/open-sidebar';
export const CLOSE_SIDEBAR = '@chat/close-sidebar';

export function getContacts() {
  const request = axios.get('/api/chat/contacts');

  return dispatch => {
    request.then(response =>
      dispatch({
        payload: response.data,
        type: GET_CONTACTS
      })
    );
  };
}

export function getThreads() {
  const request = axios.get('/api/chat/threads');

  return dispatch => {
    request.then(response =>
      dispatch({
        payload: response.data,
        type: GET_THREADS
      })
    );
  };
}

export function getThread(threadKey) {
  const request = axios.get('/api/chat/thread', {
    params: {
      threadKey
    }
  });

  return dispatch => {
    request.then(response => {
      if (response.data.thread) {
        dispatch({
          payload: response.data,
          type: GET_THREAD
        });
      }
    });
  };
}

export function markThreadAsSeen(threadKey) {
  const request = axios.get('/api/chat/thread/mark-as-seen', {
    params: {
      threadKey
    }
  });

  return dispatch => {
    request.then(() =>
      dispatch({
        payload: {
          threadKey
        },
        type: MARK_THREAD_AS_SEEN
      })
    );
  };
}

export function addMessage({ userId, threadKey, body, attachments }) {
  const request = axios.post('/api/chat/messages/new', {
    attachments,
    body,
    threadKey,
    userId
  });

  return dispatch => {
    request.then(response =>
      dispatch({
        payload: {
          userId,
          ...response.data
        },
        type: ADD_MESSAGE
      })
    );
  };
}

export function openSidebar() {
  return {
    type: OPEN_SIDEBAR
  };
}

export function closeSidebar() {
  return {
    type: CLOSE_SIDEBAR
  };
}
