import axios from 'src/utils/axios';

export const GET_BOARD = '@kanban/get-board';
export const CREATE_LIST = '@kanban/create-list';
export const UPDATE_LIST = '@kanban/update-list';
export const CLEAR_LIST = '@kanban/clear-list';
export const DELETE_LIST = '@kanban/delete-list';
export const CREATE_CARD = '@kanban/create-card';
export const UPDATE_CARD = '@kanban/update-card';
export const MOVE_CARD = '@kanban/move-card';
export const DELETE_CARD = '@kanban/delete-card';
export const ADD_COMMENT = '@kanban/add-comment';
export const ADD_CHECKLIST = '@kanban/add-checklist';
export const UPDATE_CHECKLIST = '@kanban/update-checklist';
export const DELETE_CHECKLIST = '@kanban/delete-checklist';
export const ADD_CHECK_ITEM = '@kanban/add-check-item';
export const UPDATE_CHECK_ITEM = '@kanban/update-check-item';
export const DELETE_CHECK_ITEM = '@kanban/delete-check-item';

export function getBoard() {
  const request = axios.get('/api/kanban/board');

  return dispatch => {
    request.then(response =>
      dispatch({
        payload: response.data,
        type: GET_BOARD
      })
    );
  };
}

export function createList(name) {
  const request = axios.post('/api/kanban/board/lists/new', {
    name
  });

  return dispatch => {
    request.then(response =>
      dispatch({
        payload: response.data,
        type: CREATE_LIST
      })
    );
  };
}

export function updateList(listId, update) {
  const request = axios.post('/api/kanban/board/list/update', {
    listId,
    update
  });

  return dispatch => {
    request.then(response =>
      dispatch({
        payload: response.data,
        type: UPDATE_LIST
      })
    );
  };
}

export function clearList(listId) {
  const request = axios.post('/api/kanban/board/lists/clear', {
    listId
  });

  return dispatch => {
    request.then(() =>
      dispatch({
        payload: {
          listId
        },
        type: CLEAR_LIST
      })
    );
  };
}

export function deleteList(listId) {
  const request = axios.post('/api/kanban/board/lists/remove', {
    listId
  });

  return dispatch => {
    request.then(() =>
      dispatch({
        payload: {
          listId
        },
        type: DELETE_LIST
      })
    );
  };
}

export function createCard(listId, name) {
  const request = axios.post('/api/kanban/board/cards/new', {
    listId,
    name
  });

  return dispatch => {
    request.then(response =>
      dispatch({
        payload: response.data,
        type: CREATE_CARD
      })
    );
  };
}

export function updateCard(cardId, update) {
  const request = axios.post('/api/kanban/board/cards/update', {
    cardId,
    update
  });

  return dispatch => {
    request.then(response =>
      dispatch({
        payload: response.data,
        type: UPDATE_CARD
      })
    );
  };
}

export function moveCard(cardId, position, listId) {
  const request = axios.post('/api/kanban/board/cards/move', {
    cardId,
    listId,
    position
  });

  return dispatch => {
    request.then(() =>
      dispatch({
        payload: {
          cardId,
          listId,
          position
        },
        type: MOVE_CARD
      })
    );
  };
}

export function deleteCard(cardId) {
  const request = axios.post('/api/kanban/board/cards/remove', {
    cardId
  });

  return dispatch => {
    request.then(() =>
      dispatch({
        payload: {
          cardId
        },
        type: DELETE_CARD
      })
    );
  };
}

export function addComment(userId, cardId, message) {
  const request = axios.post('/api/kanban/board/cards/comments', {
    cardId,
    message,
    userId
  });

  return dispatch => {
    request.then(response =>
      dispatch({
        payload: response.data,
        type: ADD_COMMENT
      })
    );
  };
}

export function addChecklist(cardId, name) {
  const request = axios.post('/api/kanban/board/checklists/new', {
    cardId,
    name
  });

  return dispatch => {
    request.then(response =>
      dispatch({
        payload: {
          cardId,
          ...response.data
        },
        type: ADD_CHECKLIST
      })
    );
  };
}

export function updateChecklist(cardId, checklistId, update) {
  const request = axios.post('/api/kanban/board/checklists/update', {
    cardId,
    checklistId,
    update
  });

  return dispatch => {
    request.then(response =>
      dispatch({
        payload: {
          cardId,
          checklistId,
          ...response.data
        },
        type: UPDATE_CHECKLIST
      })
    );
  };
}

export function deleteChecklist(cardId, checklistId) {
  const request = axios.post('/api/kanban/board/checklists/remove', {
    cardId,
    checklistId
  });

  return dispatch => {
    request.then(() =>
      dispatch({
        payload: {
          cardId,
          checklistId
        },
        type: DELETE_CHECKLIST
      })
    );
  };
}

export function addCheckItem(cardId, checklistId, name) {
  const request = axios.post('/api/kanban/board/checkitems/new', {
    cardId,
    checklistId,
    name
  });

  return dispatch => {
    request.then(response =>
      dispatch({
        payload: {
          cardId,
          checklistId,
          ...response.data
        },
        type: ADD_CHECK_ITEM
      })
    );
  };
}

export function updateCheckItem(cardId, checklistId, checkItemId, update) {
  const request = axios.post('/api/kanban/board/checkitems/update', {
    cardId,
    checkItemId,
    checklistId,
    update
  });

  return dispatch => {
    request.then(response =>
      dispatch({
        payload: {
          cardId,
          checkItemId,
          checklistId,
          ...response.data
        },
        type: UPDATE_CHECK_ITEM
      })
    );
  };
}

export function deleteCheckItem(cardId, checklistId, checkItemId) {
  const request = axios.post('/api/kanban/board/checkitems/remove', {
    cardId,
    checkItemId,
    checklistId
  });

  return dispatch => {
    request.then(() =>
      dispatch({
        payload: {
          cardId,
          checkItemId,
          checklistId
        },
        type: DELETE_CHECK_ITEM
      })
    );
  };
}
